import { useNavigate } from 'react-router-dom';
import './SearchUser.css';
import backArrow from './backArrow.svg';
import {useState} from 'react';
import { useLocation } from 'react-router-dom';

const SearchUserViaNumber = ()=>{
  const navigate = useNavigate();
  const location = useLocation();
  
  const path = process.env.REACT_APP_SERVER_ADDED_PATH;
  const apiKey = process.env.REACT_APP_API_PATH;
  const [selectedPlan,setUserPlan] = useState(location.state)
  console.log(selectedPlan);
  const [number,setNumber] = useState(null)
  const [fname,setfname] = useState(null)
  function getNumber(event){
    let val =event.target.value.substring(0, 10);
    event.target.value = val;
    setNumber(val);
  }
  function getfname(event){
    setfname(event.target.value);
}

const goBack = () => {
  navigate(-1); // Go back one step in the history
};

const GetData = async () => {
  try{
      const response = await fetch(`${apiKey}/customers/search?phone_number=${number}`)
      if (!response.ok) {
        console.log(response)
         //Response is not in the range 200-299, indicating an error
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      else{
        const data = await response.json(); 
        console.log(data);
        if(data[0])
        {
          
          data[0]['plan_id']=selectedPlan.plan_id;
          const navpath = (path.length>0)?'/'+path+'/verify':'/verify';
          navigate(navpath, {state:data[0]});
        }else{    
          let data = {'plan_id':selectedPlan.plan_id};
          const navpath = (path.length>0)?'/'+path+'/verify':'/verify';
          navigate(navpath, {state:data});
        }
      }
      
    }catch (error) {
       //Handle errors here
      alert("Error fetching data:", error.message);
    }
  };
  
  return (
<section className="text-center">
  <div style={{maxWidth:'50px',maxHeight:'50px' }}>
    <div className="row"><div><img src={backArrow} onClick={goBack} /></div><div style={{color:'white', fontWeight:'Bolder'}}>Back</div>
    </ div>
    </div>
    <div className="card-body-search py-5 px-md-5">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-10">
          <h2 className="fw-bold mb-5" style={{color:'white'}}>Dobson Customer Verification</h2>
          <div className='row col-lg-12 text-center' style={{color:'white'}} ><div className='mb-5 text-center'>{selectedPlan.plan_name}
            <hr />
            </div></div>
            <div className="form-outline mb-4">
              <div className='row col-md-12'> 
                <div className='col-md-2'>
                  <span className="mb-3 col-sm-4 lbl" >Phone Number</span>
                </div>
                <div className='col-md-8'>
                  <input type="number" id="number" name='number' onChange={getNumber}  className="mb-3 col-lg-12 form-control round-input" />
                </div>
                <div className='mb-3 text-center' style={{fontSize:'12px',fontFamily:'Poppins',color:'rgb(243 155 49)'}}>*You have entered an invalid email, please provide your Phone number to find your account.</div>
              </div>
            </div>
            <button type="submit" onClick={GetData} className="btn-block mb-4 roundbtn">
              Search
            </button>
        </div>
      </div>
    </div>
</section>
 )   
}
export default SearchUserViaNumber;