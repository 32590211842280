// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-body{
height: auto;
background: radial-gradient(40.22% 50.13% at 49.95% 50.13%, #257CA3 0%, #11425C 100%);
box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.33), inset 0px 4px 3px rgba(0, 0, 0, 0.39);
border-radius: 20px;
width:auto;
}

.roundbtn{
  border: none;  
padding: 10px 25px;
background: #003e52;
box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.4);
border-radius: 45px;
color:white;
font-weight: 700;
}

.card-topimg{
    border-radius: 50%;
    border: 6px solid #0f3e57;
    padding: 0px;
}


.toltip {
    position: relative;
    display: inline-block;
  }
  
  .toltip .toltiptext {
    visibility: hidden;
    width: 100%;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    opacity: 0;
    transition: opacity 0.3s;
  }
  

  
  .toltip:hover .toltiptext {
    visibility: visible;
    opacity: 1;
  }

  .truncatec{
    min-height: 65px;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space:wrap !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/LandingPage/Landing.css"],"names":[],"mappings":"AAAA;AACA,YAAY;AACZ,qFAAqF;AACrF,yFAAyF;AACzF,mBAAmB;AACnB,UAAU;AACV;;AAEA;EACE,YAAY;AACd,kBAAkB;AAClB,mBAAmB;AACnB,iDAAiD;AACjD,mBAAmB;AACnB,WAAW;AACX,gBAAgB;AAChB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;AAChB;;;AAGA;IACI,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,UAAU;IACV,wBAAwB;EAC1B;;;;EAIA;IACE,mBAAmB;IACnB,UAAU;EACZ;;EAEA;IACE,gBAAgB;IAChB,qBAAqB;IACrB,oBAAoB;IACpB,4BAA4B;IAC5B,2BAA2B;EAC7B","sourcesContent":[".card-body{\nheight: auto;\nbackground: radial-gradient(40.22% 50.13% at 49.95% 50.13%, #257CA3 0%, #11425C 100%);\nbox-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.33), inset 0px 4px 3px rgba(0, 0, 0, 0.39);\nborder-radius: 20px;\nwidth:auto;\n}\n\n.roundbtn{\n  border: none;  \npadding: 10px 25px;\nbackground: #003e52;\nbox-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.4);\nborder-radius: 45px;\ncolor:white;\nfont-weight: 700;\n}\n\n.card-topimg{\n    border-radius: 50%;\n    border: 6px solid #0f3e57;\n    padding: 0px;\n}\n\n\n.toltip {\n    position: relative;\n    display: inline-block;\n  }\n  \n  .toltip .toltiptext {\n    visibility: hidden;\n    width: 100%;\n    background-color: #555;\n    color: #fff;\n    text-align: center;\n    border-radius: 6px;\n    padding: 5px 0;\n    position: absolute;\n    z-index: 1;\n    bottom: 125%;\n    opacity: 0;\n    transition: opacity 0.3s;\n  }\n  \n\n  \n  .toltip:hover .toltiptext {\n    visibility: visible;\n    opacity: 1;\n  }\n\n  .truncatec{\n    min-height: 65px;\n    -webkit-line-clamp: 4;\n    display: -webkit-box;\n    -webkit-box-orient: vertical;\n    white-space:wrap !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
