import './Landing.css';
import logo from './logo.png';
import DOMPurify from "dompurify";
import TechProtection from "./Tech Protection.jpg"
import TechProtectionPremium from "./Tech Protection Premium.jpg"
import TechProtectionPlus from "./Tech Protection Plus.jpg"
import React, { useState, useEffect } from 'react';
import {  NavLink, useNavigate} from 'react-router-dom';
import Modal from 'react-modal';
const Landing = ()=>{
  Modal.setAppElement('#root');
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const path = process.env.REACT_APP_SERVER_ADDED_PATH;
  const apiKey = process.env.REACT_APP_API_PATH;
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch(`${apiKey}/plans`);

        if (!response.ok) {
          throw new Error('Failed to fetch plan data');
        }

        const data = await response.json();
        console.log(data);
        setPlans(data);
      } catch (error) {
        setError(error.message);
      }
    };
    
    fetchPlans();
  }, [])
  const handleSub = (plan) => {
    console.log('Selected Plan:', plan);
    const navpath = (path.length>0)?'/'+path+'/search':'/search';
    navigate(navpath, { state: { selectedPlan: plan } });
  };

  const handleViewMore = (plan) => {
   setSelectedPlan(plan);
   setShowMoreModal(true);
};

const handleToggleMore = () => {
  setShowMore(!showMore);
};
return (
<section className="justify-content-center">
<div className="row justify-content-center" >
    <img src={logo} className="col-2" alt='logo' style={{maxHeight:'100px',maxWidth:'100px'}}/>
</div>
<div className="h1 fw-bold text-center" style ={{color:'White',fontFamily:'Poppins',fontWeight:'900',fontSize:'50px',lineHeight:'75px'}}>
<span style={{color:'#19d3c5'}}>Ready</span> to Get Started?
</div>
<div className="text-center mb-5" style ={{color:'White',fontFamily:'Poppins', fontSize:'larger'}}>
Select The Plan That Best Suits Your Needs And Enhances Your Experience.
</div>
<div className="row justify-content-center" style={{color:'white'}}>
  {plans.map((plan) => (
      <div key={plan.plan_id} className="col-md-3 mb-5">
        <div className="card-body py-5 px-md-5 mx-md-4">

          <div className="row d-flex justify-content-center">
        {plan.plan_name === "Tech Protection" && <img src={TechProtection} className="col-6 card-topimg" alt="Tech Protection" />}
        {plan.plan_name === "Tech Protection Premium" && <img src={TechProtectionPremium}  className="col-6 card-topimg" alt="Tech Protection Premium" />}
        {plan.plan_name === "Tech Protection Plus" && <img src={TechProtectionPlus}  className="col-6 card-topimg" alt="Tech Protection Plus" />}
            <div className="col-sm-12 justify-content-center text-center">
            <div className='row plan'>
              <span style={{color:'white', fontSize:'medium', fontFamily:'Poppins', fontWeight:'bolder'}}>
                {plan.plan_name}
              </span>
            </div>
              <hr />
              <div className="col-sm-12 ">
              <div className='text-start text-justify text-truncate truncatec' style={{fontSize:'11px',fontFamily:'Poppins'}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(plan.plan_description) }}>
              </div>
              <div className="row mb-3" onClick={() => handleViewMore(plan)} style={{color:'#19d3c5',fontFamily:'Poppins'}} ><u>View More</u>
                  
                   
                   </div>

              </div>
              <div className="h1 fw-bold text-center" style ={{color:'White',fontFamily:'Poppins'}}>
                <span style={{color:'#19d3c5',fontFamily:'Poppins'}}>${plan.retail_cost} </span> 
              </div>
              <div className='justify-content-center'>
                    <button type="submit" onClick={() => handleSub(plan)} className=" mb-4 roundbtn">
                      Subscribe
                    </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  ))}
</div>  
<Modal
   isOpen={showMoreModal}
   contentLabel="Plan Description Modal"
   className="custom-modal"
>
   <h2>{selectedPlan && selectedPlan.plan_name}</h2>
   <div className='text-start' style={{fontSize:'12px',fontFamily:'Poppins'}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedPlan && selectedPlan.plan_description) }}></div>
   <a href="/Dobson TCS.pdf" download>
            Terms and Conditions
          </a>
           <div>
          {selectedPlan && selectedPlan.device_covered && (
          <a style={{color:'#19d3c5',fontFamily:'Poppins'}} href="#" onClick={handleToggleMore}>
            {showMore ? 'Hide Covered Devices' : 'Show covered Devices'}
          </a>)}
        </div>

        {showMore && selectedPlan &&  selectedPlan.device_covered &&(
          <div>
            <h4>Covered Devices</h4>
            <div className='text-start' style={{fontSize:'12px',fontFamily:'Poppins'}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedPlan && selectedPlan.device_covered) }}>

            </div>
          </div>
        )}
   <button className="roundbtn" onClick={() => setShowMoreModal(false)}>Close</button>
</Modal>
</section>
 )   
}
export default Landing;