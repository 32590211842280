import { useNavigate,useLocation } from 'react-router-dom';
import './SearchUser.css';
import backArrow from './backArrow.svg';
import {useState} from 'react';
const SearchUser = ()=>{
  const apiKey = process.env.REACT_APP_API_PATH;
  const path = process.env.REACT_APP_SERVER_ADDED_PATH;
  const location = useLocation();
  const selectedPlan = location.state ? location.state.selectedPlan : null;
  const navigate = useNavigate();
  //const [userData,setUserData] = useState(null)
  
  const [email,setEmail] = useState(null)
  let emailValidity = false;

  function getEmail(event){
      if(event.target.checkValidity())
      {
          emailValidity = true;
          setEmail(event.target.value);
      }else
      {
        emailValidity = false;
      } 
  }
  const GetData = async () => {
  try{
      const response = await fetch(`${apiKey}/customers/search?email=${email}`)
      if (!response.ok) {
        console.log(response)
         //Response is not in the range 200-299, indicating an error
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      else{
        const data = await response.json(); 
        console.log(data);
        if(data[0])
        {
          data[0]['plan_id']=selectedPlan.plan_id;

          const navpath = (path.length>0)?'/'+path+'/verify':'/verify';
          navigate(navpath, {state:data[0]});
        }else{
              
          const navpath = (path.length>0)?'/'+path+'/searchnumber':'/searchnumber';
            navigate( navpath, {state:selectedPlan});
        }
      }
      
    }catch (error) {
       //Handle errors here
      alert("Error fetching data:", error.message);
    }
  };
 return (
<section className="text-center">
    <div className="card-body-search py-5 px-md-5">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-8">
          <h2 className="fw-bold mb-5" style={{color:'white'}}>Dobson Customer Verification</h2>
            <div className='row col-lg-12 text-center' style={{color:'white'}} >
              <div className='mb-3 text-center'>{selectedPlan.plan_name}
            <hr />
            </div></div>
              <div className='mb-3 text-center' style={{color:'#D7D7D7'}}>Please enter the email address associated with your Dobson Fiber account.</div>
            <div className="form-outline mb-4">
              <div className='row col-lg-12'> 
                <div className='col-sm-2'>
                  <span className="mb-3 col-sm-4 lbl" >Email Address</span>
                </div>
                <div className='col-sm-10'>
                  <input type="email" id="email" name='email' onChange={getEmail}  className="mb-3 col-lg-12 form-control round-input" />
                </div>
              </div>
            </div>
            <button type="submit" onClick={GetData} className="btn-block mb-4 roundbtn">
              Search
            </button>
        </div>
      </div>
    </div>
</section>
 )   
}
export default SearchUser;