import './App.css';
//import SearchUser from './components/Users/SearchUser'
import {
  Outlet
} from "react-router-dom";

function App() {
  return (
    
    <div className="App">
      <header className="conatiner">
      <svg width="100%" height="100%" viewBox="0 0 1437 820" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M-6 231.214C93.569 254.827 297.21 358.641 315.223 584.988C333.235 811.336 677.641 830.311 847.592 811.505C956 791.003 1136.69 689.084 992.192 445.43C847.692 201.775 1227.86 48.9539 1436 3" stroke="url(#paint0_linear_204_125)" strokeOpacity="0.13" strokeWidth="6"/>
      <defs>
      <linearGradient id="paint0_linear_204_125" x1="715" y1="3" x2="715" y2="817" gradientUnits="userSpaceOnUse">
    <stop offset="0.0260417" stopColor="#C0B7E8"/>
    <stop offset="0.265625" stopColor="#C0B7E8"/>
    <stop offset="0.71875" stopColor="#8176AF"/>
      <stop offset="1" stopColor="#343045"/>
    </linearGradient>
    </defs>
    </svg>
    </header>
    <Outlet />
    </div>
  );
}

export default App;