import './SearchUser.css';
import {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';
const UserVerification = ()=>{
  const apiKey = process.env.REACT_APP_API_PATH;
  const [showModal, setShowModal] = useState(false);
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const location = useLocation();
  const [userData,setUserData] = useState(location.state)
  const [plans, setPlans] = useState([]);
  const handleInputChange = (event) => {
  const { name, value } = event.target;
  setUserData({
    ...userData,
    [name]: value,
  });
  console.log(userData);
};

const handleOpenModal = () => {
  setShowModal(true);
};

const handleCloseModal = () => {
  setShowModal(false);
};

const handleConfirmation = () => {
  const checkbox = document.getElementById('confirmationCheckbox');

  if (checkbox && checkbox.checked) {
    // Checkbox is checked, proceed with submission
    handleSubmit();
    handleCloseModal();
  } else {
    alert('Please confirm before submitting.');
};
}
const handleSubmit = async () => {

  try {
    // Make a POST request to your API
    let apiUrl = (userData.customer_id)?apiKey+'/customer_trans':apiKey+'/customers';

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });

    // Check if the request was successful (status code 2xx)
    if (response.ok) {
      const result = await response.json();
      setShowThankYouModal(true);
      // Handle success as needed
    } else {
      // Handle error response
      console.error('Failed to create customer:', response.statusText);

    }
  } catch (error) {
    console.error('Error:', error.message);
  }
};

useEffect(() => {
  const fetchPlans = async () => {
    try {
      const response = await fetch(`${apiKey}/plans`);

      if (!response.ok) {
        throw new Error('Failed to fetch plan data');
      }

      const data = await response.json();
      console.log(data);
      setPlans(data);
    } catch (error) {
      alert('something went wrong');
    }
  };
  
  fetchPlans();
}, [])

return (
<section className="text-center">
  <div className="card mx-4 mx-md-5 shadow-5-strong">
    <div className="card-body-search py-5 px-md-5">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-10">
          <h2 className="fw-bold mb-5" style={{color:'white'}}>Dobson Customer Verification</h2>
            <div className="form-outline mb-4">
              <div className='row col-md-12'> 
                <div className='col-sm-2'>
                  <span className="mb-3 col-sm-4 lbl" >Selected Plan</span>
                </div>
                <div className='col-sm-10'>
                  <select  className="mb-3 col-lg-12 customdrop" name='plan_id' onChange={handleInputChange}  value={userData.plan_id ? userData.plan_id : ''}>
                  {plans.map(plan => (
          <option key={plan.value} value={plan.plan_id}>{plan.plan_name}</option>
                  ))}
                    </select>
                </div>
              </div>
            </div>
           <div className="row">
            <div className='col-md-6'> 
                <div className='col-sm-6  text-start'>
                  <span className="mb-3 col-sm-4 lbl" >First Name</span>
                </div>
                <div className='col-sm-12 text-start'>
                <input type="hidden"   name='customer_id' onChange={handleInputChange} className="mb-3 col-lg-12 form-control round-input" value={userData.customer_id} />
                  <input type="text" id="fname"  name='First_Name' onChange={handleInputChange} className="mb-3 col-lg-12 form-control round-input" value={userData.first_name} />
                </div>
              </div>
              <div className='col-md-6'> 
                <div className='col-sm-6 text-start'>
                  <span className="mb-3 col-sm-4 lbl" >Last Name</span>
                </div>
                <div className='col-sm-12'>
                  <input type="text"   name='Last_Name' id="lname"  onChange={handleInputChange} className="mb-3 col-lg-12 form-control round-input" value={userData.last_name} />
                </div>
              </div>
            </div>
            <div className="row">
            <div className='col-md-6'> 
                <div className='col-sm-6 text-start'>
                  <span className="mb-3 col-sm-4 lbl" >Email</span>
                </div>
                <div className='col-sm-12'>
                  <input type="email" id="mail" name='email' onChange={handleInputChange} className="mb-3 col-lg-12 form-control round-input" value={userData.email}/>
                </div>
              </div>
              <div className='col-md-6'> 
                <div className='col-md-6 text-start'>
                  <span className="mb-3 col-sm-4 lbl" >Phone Number</span>
                </div>
                <div className='col-md-12'>
                  <input type="text" id="phone" name='phone_number' onChange={handleInputChange}  className="mb-3 col-lg-12 form-control round-input "  value={userData.phone_number}/>
                </div>
              </div>
            </div>
            <div className="row justify-content-center ">
            <div className='col-md-6'> 
                <div className='col-sm-6 text-start'>
                  <span className="mb-3 col-sm-4 lbl" >Zip Code</span>
                </div>
                <div className='col-sm-12'>
                  <input type="email" id="zipcode" name='zip_code' onChange={handleInputChange} className="mb-3 col-lg-12 form-control round-input"  value={userData.zip_code} />
                </div>
              </div>
                <div className='mb-3 text-center' style={{color:'#D7D7D7'}}>
                  If you feel this information is incorrect please contact Dobson support at 855-536-2766.
                </div>
            </div>
            <button onClick={handleOpenModal} className="btn-block mb-4 roundbtn " >
              Submit
            </button>

        </div>
      </div>
    </div>
  </div>
  <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        contentLabel="Confirmation Modal"
      >
        <p> <input type="checkbox"  id ='confirmationCheckbox' className='chkbx form-check-input' 
        />By clicking this box you are acknowledging that this new plan will be added to your monthly bill</p>
        <div>
          <button onClick={handleConfirmation}>Submit</button>
        </div>
  </Modal>
  <Modal
        isOpen={showThankYouModal}
        contentLabel="Thank You Modal"
        className="custom-modal"
      >
        <h2>Thank You for Registering!</h2>
        <p>
          You will receive an email within 7 days with instructions on how to
          register your account.
        </p>
      </Modal>
</section>
 )   
}
export default UserVerification;