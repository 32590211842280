// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App{
position: absolute;
height: 100%;
width: 100%;
background: #33647E;
}

.form-control{
    color:white;
}

.conatiner{
position: absolute;
width: auto;
height: auto;
left: -6px;
top: 126px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;AACA,kBAAkB;AAClB,YAAY;AACZ,WAAW;AACX,mBAAmB;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;AACA,kBAAkB;AAClB,WAAW;AACX,YAAY;AACZ,UAAU;AACV,UAAU;AACV","sourcesContent":[".App{\nposition: absolute;\nheight: 100%;\nwidth: 100%;\nbackground: #33647E;\n}\n\n.form-control{\n    color:white;\n}\n\n.conatiner{\nposition: absolute;\nwidth: auto;\nheight: auto;\nleft: -6px;\ntop: 126px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
