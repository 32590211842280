import React from 'react';
import ReactDOM from 'react-dom/client';
import UserVerification from './components/Users/UserVerification'
import SearchUserViaNumber from './components/Users/SearchUserViaNumber'
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap'
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    Route,
    createRoutesFromElements,
    RouterProvider,
  } from "react-router-dom";
import Landing from './components/LandingPage/Landing';
import SearchUser from './components/Users/SearchUser';
const path = process.env.REACT_APP_SERVER_ADDED_PATH;
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path={path} element={<App />} >
        <Route path="" element={<Landing />} />
        <Route path="search" element={<SearchUser /> } />
        <Route path="verify" element={<UserVerification/>} />
        <Route path="searchnumber" element={<SearchUserViaNumber/>} />

      </Route>
    )
  );
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
